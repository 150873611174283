// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/index.ts"
);
import.meta.hot.lastModified = "1731998591000";
}
// REMIX HMR END

import dayjs, { Dayjs } from 'dayjs';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { convertToMonetaryValue } from './MonetaryValue';

export function roundNumber(
  value: number,
  decimals: number = 0,
  method: 'nearest' | 'up' | 'down' = 'nearest'
): number | undefined {
  if (typeof value !== 'number' || isNaN(value)) {
    return undefined;
  }
  if (typeof decimals !== 'number' || decimals < 0) {
    return undefined;
  }
  if (!['nearest', 'up', 'down'].includes(method)) {
    return undefined;
  }

  const factor = Math.pow(10, decimals ?? 2);

  switch (method) {
    case 'nearest':
      return Math.round(value * factor) / factor;
    case 'up':
      return Math.ceil(value * factor) / factor;
    case 'down':
      return Math.floor(value * factor) / factor;
    default:
      return Math.round(value * factor) / factor;
  }
}

export const handleThousandCommaSeparator = (num: number) => {
  if (!num) return '';

  const numericOnly = num?.toString()?.replace(/[^0-9]/g, '');
  return numericOnly?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addThousandSeparator = (
  value: string | number,
  invalidString = '0.00',
  hasDecimal = true
) => {
  if (hasDecimal) return convertToMonetaryValue(value, invalidString);

  if (!value) return invalidString ?? '';

  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeThousandSeparator = (value: string) => {
  if (!value) return '';

  return value?.replace(/,(?=\d{3})/g, '');
};

export const toISODateString = (date: string | Dayjs) => {
  if (!date) return '';

  let formattedDate = '';

  try {
    formattedDate = dayjs(date, 'DD/MM/YYYY').toISOString();
  } catch (error) {
    console.error('parse date error', error);
    //
  }

  return formattedDate;
};

export const formatDateToDMY = (date: string): string => {
  if (!date) return '';

  return dayjs(date)?.format('DD/MM/YYYY');
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertToBoolean(
  value: string | number | boolean | undefined
): boolean {
  switch (typeof value) {
    case 'string': {
      const trueCondition = ['yes', 'true', '1'];
      const falseCondition = ['no', 'false', '0'];

      if (trueCondition.includes(value)) {
        return true;
      } else if (falseCondition.includes(value)) {
        return false;
      }
      throw new Error('Failed to convert from string');
    }
    case 'number': {
      const trueCondition = [1];
      const falseCondition = [0];

      if (trueCondition.includes(value)) {
        return true;
      } else if (falseCondition.includes(value)) {
        return false;
      }
      throw new Error('Failed to convert from number');
    }
    default: {
      throw new Error('Failed to convert');
    }
  }
}

export function convertBooleanToString(value: boolean) {
  return value ? 'true' : 'false';
}
export function convertBooleanToNumber(value: boolean) {
  return value ? 1 : 0;
}

export function getOnlyNumber(value: string) {
  return value.replace(/\D/g, '');
}

export function generateRandomAlphanumeric(length = 10) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateRandomNumber(length: number): number {
  if (length <= 0) {
    throw new Error('Length must be greater than zero.');
  }

  const min = Math.pow(10, length - 1);
  const max = Math.pow(10, length) - 1;

  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  return randomNumber;
}

export function debounce<T extends (...args: any[]) => any>(
  ms: number,
  callback: T
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise<ReturnType<T>>((resolve) => {
      timer = setTimeout(() => {
        const returnValue = callback(...args) as ReturnType<T>;
        resolve(returnValue);
      }, ms);
    });
  };
}

export function keyToTitle(key: string) {
  return key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}
